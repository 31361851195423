<template>
  <b-container fluid class="wrapper p-5">
    <b-row
      style="border-radius: 20px; background-color: #fff"
      class="p-4 ml-4 mr-4 pb-5"
    >
      <b-col>
        <div>
          <!-- ACCOUNT DETAILS -->
          <validation-observer ref="accountForm" v-slot="{ valid }">
            <b-form>
              <b-row class="no-gutters align-items-center">
                <b-col>
                  <h5>
                    <!-- {{ $t("accountPersonalInformation") }} -->
                  </h5>
                </b-col>
                <b-col class="col-auto">
                  <b-button
                    v-if="editAccountStatus"
                    size="sm"
                    class="text-uppercase p-3 action-btn mr-2"
                    variant="outline-gray-dark"
                    @click="cancelEditAccountForm"
                  >
                    <span>{{ $t("button.cancel") }}</span>
                  </b-button>
                  <b-button
                    v-if="editAccountStatus"
                    size="sm"
                    class="text-uppercase save-btn p-3"
                    variant="none"
                    @click="submitAccount"
                    :disabled="!valid"
                  >
                    <span>{{ $t("button.save") }}</span>
                  </b-button>
                  <b-button
                    v-else
                    size="sm"
                    class="text-uppercase p-3 action-btn mr-2"
                    variant="outline-gray-dark"
                    @click="activateEditAccountForm"
                  >
                    <img
                      src="./../assets/general-icons/pencil-alt-2.svg"
                      class="action__icon clickable mr-1"
                    />
                    <span>{{ $t("button.edit") }}</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col xl="3" lg="4" md="5" sm="6">
                  <h5>
                    {{ $t("accountPersonalInformation") }}
                  </h5>
                </b-col>
                <b-col xl="9" lg="8" md="7" sm="6">
                  <b-row class="no-gutters">
                    <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                      <b-form-group
                        label-class="font-weight-bold"
                        :label="$t('firstName')"
                        label-for="firstName"
                      >
                        <validation-provider
                          v-if="editAccountStatus"
                          immediate
                          :name="$t('firstName')"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                        >
                          <b-form-input
                            id="firstName"
                            class="input__field"
                            v-model="clientAccountInfo.firstName"
                            :state="getValidationState(validationContext)"
                          ></b-form-input>
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                        <div v-else class="text-muted mt-n2">
                          {{ clientAccountInfo.firstName }}
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                      <b-form-group
                        label-class="font-weight-bold"
                        :label="$t('lastName')"
                        label-for="lastName"
                      >
                        <validation-provider
                          v-if="editAccountStatus"
                          :name="$t('lastName')"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                        >
                          <b-form-input
                            id="lastName"
                            class="input__field"
                            v-model="clientAccountInfo.lastName"
                            :state="getValidationState(validationContext)"
                          ></b-form-input>
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                        <div v-else class="text-muted mt-n2">
                          {{ clientAccountInfo.lastName }}
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                      <b-form-group
                        label-class="font-weight-bold"
                        :label="$t('email')"
                        label-for="email"
                      >
                        <!-- <validation-provider
                      v-if="editAccountStatus"
                      :name="$t('email')"
                      :rules="{ required: true, email: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="email"
                        class="input__field"
                        v-model="clientAccountInfo.email"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider> -->
                        <div class="text-muted mt-n2">
                          {{ clientAccountInfo.email }}
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                      <b-form-group
                        label-class="font-weight-bold"
                        :label="$t('jobTitle')"
                        label-for="jobTitle"
                      >
                        <validation-provider
                          v-if="editAccountStatus"
                          :name="$t('jobTitle')"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                        >
                          <b-form-input
                            id="jobTitle"
                            class="input__field"
                            v-model="clientAccountInfo.jobTitle"
                            :state="getValidationState(validationContext)"
                          ></b-form-input>
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                        <div v-else class="text-muted mt-n2">
                          {{ clientAccountInfo.jobTitle }}
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                      <b-form-group
                        label-class="font-weight-bold"
                        :label="$t('phone')"
                        label-for="phone"
                      >
                        <validation-provider
                          v-if="editAccountStatus"
                          :name="$t('phone')"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                        >
                          <b-form-input
                            id="phone"
                            class="input__field"
                            v-model="clientAccountInfo.phone"
                            :state="getValidationState(validationContext)"
                          ></b-form-input>
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                        <div v-else class="text-muted mt-n2">
                          {{ clientAccountInfo.phone }}
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
            <hr class="divider" />
          </validation-observer>
          <!-- CHANGE PROFILE IMAGE -->
          <validationObserver ref="accountForm" v-slot="">
            <b-form class="mt-5 mb-5">
              <b-row>
                <b-col xl="3" lg="4" md="5" sm="6">
                  <h5 class="mb-0">
                    {{ $t("profileImage") }}
                  </h5>
                </b-col>
                <b-col xl="9" lg="8" md="7" sm="6">
                  <div class="imageEdit-wrapper ">
                    <img
                      class="circle-img"
                      src="../assets/user/default-user-icon.png"
                      alt=""
                    />
                    <b-button 
                    class="text-uppercase p-2 action-btn ml-3"
                    variant="outline-gray-dark"
                      ><img
                        class="standar-icon-height ml-2"
                        src="../assets/general-icons/trash-icon2.svg"
                      /><span class="ml-2">{{ $t("remove") }}</span></b-button
                    >
                    <b-button
                      variant="none"
                      class="ml-3 upload-btn"
                      ><img
                        class="standar-icon-height"
                        src="../assets/general-icons/upload-alt.svg"
                        alt=""
                      /><span class="ml-2">{{
                        $t("uploadImage")
                      }}</span></b-button
                    >
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <hr class="divider" />
          </validationObserver>

          <!-- CHANGE PASSWORD -->
          <validation-observer ref="accountForm" v-slot="{ valid }">
            <b-form>
              <b-row>
                <b-col xl="3" lg="4" md="5" sm="6">
                  <h5 class="">
                    {{ $t("changePassword") }}
                  </h5>
                </b-col>
                <b-col xl="9" lg="8" md="7" sm="6">
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('currentPassword')"
                    label-for="currentPassword"
                  >
                    <validation-provider
                      :name="$t('currentPassword')"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        v-model="currentPassword"
                        type="password"
                        class="input__field"
                        :placeholder="$t('currentPassword')"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t('newPassword')"
                  >
                    <p class="small text-muted">
                      {{ $t("passwordRulesText") }}
                    </p>
                    <validation-provider
                      name="newPassword"
                      :rules="{ required: true, password: true }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        class="input__field"
                        v-model="newPassword"
                        type="password"
                        :placeholder="$t('newPassword')"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                    <validation-provider
                      :name="$t('passwordConfirmation')"
                      rules="required|passwordMatch:@newPassword"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        v-model="passwordConfirmation"
                        class="mt-3 input__field"
                        type="password"
                        :placeholder="$t('passwordConfirmation')"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-row class="no-gutters">
                    <b-button
                      class="ml-auto secondary__btn action-btn"
                      :disabled="!valid"
                      @click="changePassword"
                      variant="dark"
                    >
                      {{ $t("button.updatePassword") }}
                    </b-button>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
            <hr class="divider" />
          </validation-observer>

          <!-- DELETE ACCOUNT -->
          <validationObserver ref="accountForm" v-slot="">
            <b-form>
              <b-row>
                <b-col xl="3" lg="4" md="5" sm="6">
                  <h5 class="mb-2">
                    {{ $t("Delete Account") }}
                  </h5>
                  <h6 class="mb-0 font-weight-normal">
                    {{ $t("deleteAccountWarning") }}
                  </h6>
                </b-col>
                <b-col xl="9" lg="8" md="7" sm="6">
                  <b-button
                  variant="outline-gray-dark"
                  class="action-btn"
                    
                  >
                    {{ $t("deleteAccount") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
            <hr class="divider" />
          </validationObserver>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Utils from "./../Utils";
export default {
  watch: {
    newsletter: {
      handler: function () {
        if (this.dataLoaded) this.changeNewsletterPreference();
      },
      immediate: false,
    },
  },
  data() {
    return {
      dataLoaded: false,
      countryList: Utils.COUNTRY_LIST,
      editAccountStatus: false,
      editBillingStatus: false,
      clientAccountInfo: {},
      clientBillingInfo: {},
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: "",
      newsletter: false,
      privacyLinks: {
        hrefEn: "https://emotiva.it/en/empower-privacy-policy",
        hrefIt: "https://emotiva.it/empower-privacy-policy-2/",
      },
    };
  },
  created() {
    this.initAccountInfo();
    this.initBillingInfo();
    this.initNewsletter();
  },
  computed: {
    userAccount() {
      return { ...this.$store.getters["auth/getUserAccount"] };
    },
  },
  methods: {
    getExternalLink(linkItem) {
      return Utils.getExternalLinkByLang({
        linkEn: linkItem.hrefEn,
        linkIt: linkItem.hrefIt,
      });
    },
    initAccountInfo() {
      this.clientAccountInfo = {
        firstName: this.userAccount.firstName,
        lastName: this.userAccount.lastName,
        email: this.userAccount.registration?.email
          ? this.userAccount.registration.email
          : "-",
        jobTitle: this.userAccount.jobTitle,
        phone: this.userAccount.phone,
      };
      this.editAccountStatus = false;
    },
    initBillingInfo() {
      this.clientBillingInfo = {
        companyName: this.userAccount.companyName,
        country: this.userAccount.country,
        city: this.userAccount.city,
        address: this.userAccount.address,
        province: this.userAccount.province,
        vat: this.userAccount.vat,
        sdlCode: this.userAccount.sdlCode,
        pec: this.userAccount.pec,
        zip: this.userAccount.zip,
      };
      this.editBillingStatus = false;
    },
    initNewsletter() {
      // Use 'dataLoaded' in order to prevent send request on init (in watch)
      this.$nextTick(() => {
        this.dataLoaded = true;
      });
      this.newsletter = this.userAccount.newsletterRegistration;
    },
    getValidationState({ dirty, validated, valid = null, untouched }) {
      // if (!untouched && (dirty || validated)) return valid ? null : valid;
      // else return null;
      if (!untouched) return valid ? null : valid;
      else return null;
    },
    activateEditAccountForm() {
      this.editAccountStatus = true;
    },
    cancelEditAccountForm() {
      this.initAccountInfo();
    },
    submitAccount() {
      const currentUserAccount = this.userAccount;
      currentUserAccount.firstName = this.clientAccountInfo.firstName;
      currentUserAccount.lastName = this.clientAccountInfo.lastName;
      // Is this actually needed if email is not mutable after initial user creation? Disabling for now
      // currentUserAccount.registration.email = this.clientAccountInfo.email;
      currentUserAccount.jobTitle = this.clientAccountInfo.jobTitle;
      currentUserAccount.phone = this.clientAccountInfo.phone;
      this.updateUserAccount(currentUserAccount, this.initAccountInfo);
    },
    activateEditBillingForm() {
      this.editBillingStatus = true;
    },
    cancelEditBillingForm() {
      this.initBillingInfo();
    },
    submitBillingInfo() {
      const currentUserAccount = this.userAccount;
      currentUserAccount.companyName = this.clientBillingInfo.companyName;
      currentUserAccount.country = this.clientBillingInfo.country;
      currentUserAccount.city = this.clientBillingInfo.city;
      currentUserAccount.address = this.clientBillingInfo.address;
      currentUserAccount.province = this.clientBillingInfo.province;
      currentUserAccount.vat = this.clientBillingInfo.vat;
      currentUserAccount.sdlCode = this.clientBillingInfo.sdlCode;
      currentUserAccount.pec = this.clientBillingInfo.pec;
      currentUserAccount.zip = this.clientBillingInfo.zip;
      this.updateUserAccount(currentUserAccount, this.initBillingInfo);
    },
    changePassword() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("auth/changeUserPassword", {
          oldPassword: this.currentPassword,
          newPassword: this.newPassword,
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    changeNewsletterPreference() {
      const currentUserAccount = this.userAccount;
      currentUserAccount.newsletterRegistration = this.newsletter;
      this.updateUserAccount(currentUserAccount);
    },
    updateUserAccount(userAccount, resetClb = null) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store.commit("auth/SET_USER_ACCOUNT", userAccount);
      this.$store
        .dispatch("auth/updateClient")
        .then(() => {
          if (resetClb) resetClb();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
  },
};
</script>

<style scoped>
.divider {
  border-top: 1px #e2e7f0 solid;
}
.save-btn{
  border-radius: 46px;
  background-color: var(--emotiva-pink);
  min-width:90px ;
  color:#fff
}
.action-btn{
  border-radius: 46px;
  min-width:100px ;
}
.upload-btn{
  background-color: var(--emotiva-pink-very-light);
  color:var(--emotiva-pink);
  border-radius: 46px;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
}

.imageEdit-wrapper {
  display: flex;
  align-items: center;
  max-height: 56px;
}
.action__icon {
  width: 14px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
.input__field{
  height: 46px;
  border-radius: 10px;
}
.input__field.form-control:disabled {
  border: unset;
  background-color: initial;
  padding: 0;
  color: #6c757d;
  margin-top: -16px;
}
.secondary__btn.btn-secondary.disabled,
.secondary__btn.btn-secondary:disabled {
  color: black;
}
.circle-img {
  padding: 5px;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background-color: gainsboro;
}
.standar-icon-height {
  height: 22px;
}
</style>
